<template>
    <div>
        <ts-page-title
            :title="$t('payslip.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('payslip.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-md-3">
                        <div class="row mb-3">
                            <div>
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('payslip.template') }}</label
                                >
                                <Select
                                    v-model="template_id"
                                    :clearable="true"
                                    :class="{
                                        'is-invalid': errors.has('template_id')
                                    }"
                                >
                                    <Option
                                        v-for="(payslip,
                                        index) in payslipTemplate"
                                        :value="payslip.template_id"
                                        :key="index"
                                    >
                                        {{ payslip.template_type }}
                                    </Option>
                                </Select>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('template_id')"
                                >
                                    {{ errors.first('template_id') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('payslip.cycleYear') }}</label
                                >
                                <date-Picker
                                    :value="cycle_year"
                                    type="year"
                                    placeholder="Select Year"
                                    format="yyyy"
                                    @on-change="cycleYearChange"
                                    :class="{
                                        'is-invalid': errors.has('cycle_year')
                                    }"
                                ></date-Picker>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_year')"
                                >
                                    {{ errors.first('cycle_year') }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('payslip.cycleMonth') }}</label
                                >
                                <date-Picker
                                    :value="cycle_month"
                                    type="month"
                                    placeholder="Select Month"
                                    format="MM"
                                    @on-change="cycleMonthChange"
                                    :class="{
                                        'is-invalid': errors.has('cycle_month')
                                    }"
                                ></date-Picker>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_month')"
                                >
                                    {{ errors.first('cycle_month') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-label form-label tw-text-xs">{{
                                $t('payslip.branchNameEn')
                            }}</label>
                            <ts-branch-filter
                                @filter="value => (branch_id = value)"
                                :isPlaceholder="true"
                            />
                        </div>
                        <div class="row mb-3">
                            <div>
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('payslip.employee') }}</label
                                >
                                <Select
                                    v-model="employee_id"
                                    :loading="loading"
                                    remote
                                    filterable
                                    :clearable="true"
                                    :remote-method="searchemployee"
                                    :class="{
                                        'is-invalid': errors.has('employee_id')
                                    }"
                                >
                                    <Option
                                        v-for="(employee,
                                        index) in employeeProfile"
                                        :value="employee.employee_id"
                                        :key="index"
                                    >
                                        {{ employee.card_id }}|
                                        {{ employee.employee_name_en }}|
                                        {{ employee.employee_name_kh }}
                                    </Option>
                                </Select>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('employee_id')"
                                >
                                    {{ errors.first('employee_id') }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3"
                            >
                                <b-dropdown>
                                    <template v-slot:button-content>
                                        <span class="inline-block">{{
                                            $t('payslip.export')
                                        }}</span>
                                    </template>
                                    <b-dropdown-item
                                        href="#"
                                        @click="exportExcel"
                                    >
                                        <i
                                            class="fas fa-file-excel tw-text-green-500"
                                        ></i>
                                        {{ $t('payslip.excel') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="exportPdf">
                                        <i
                                            class="fas fa-file-pdf tw-text-red-500"
                                        ></i>
                                        {{ $t('payslip.pdf') }}
                                    </b-dropdown-item>
                                </b-dropdown>
                                <ts-button
                                    color="primary"
                                    :waiting="viewing"
                                    @click="fetchData"
                                >
                                    {{ $t('view') }}
                                </ts-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <h3 class=" tw-text-sm">Report View</h3>
                        <div class="tw-overflow-auto" style="max-height: 80vh">
                            <div>
                                <payslip-header
                                    :dataHeader="payslip.Header"
                                    v-if="payslip.Header"
                                />
                                <payslip-body
                                    :dataBody="payslip.Body"
                                    v-if="payslip.Body"
                                />

                                <payslip-footer
                                    :dataFooter="payslip.Footer"
                                    v-if="payslip.Footer"
                                />
                            </div>
                        </div>
                    </div>
                    <Modal
                        v-model="showForm"
                        draggable
                        sticky
                        scrollable
                        :mask="false"
                        :footer-hide="true"
                        :z-index="1022"
                        width="1100px"
                        max-height="1500"
                        :title="$t('payslip.previewPdf')"
                    >
                        <div id="container">
                            <i
                                class="fa fa-spinner fa-spin fa-2x"
                                v-if="previewing"
                            >
                            </i>
                            <preview-pdf :src="src" :previewing="previewing" />
                        </div>
                    </Modal>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import { debounce } from 'lodash'
import PayslipHeader from './payslip-header.vue'
import PayslipBody from './payslip-body.vue'
import PayslipFooter from './payslip-footer.vue'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'
import PreviewPdf from './preview-pdf.vue'

export default {
    name: 'payslip',
    components: { PayslipHeader, PayslipBody, PayslipFooter, PreviewPdf },
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            cycle_year: moment().format('YYYY'),
            cycle_month: moment().format('MM'),
            payslipTemplate: [],
            template_id: [],
            employeeProfile: [],
            employee_id: [],
            branch_id: [],
            branches: [],
            previewing: false,
            showForm: false,
            src: '',
            viewing: false
        }
    },
    computed: {
        ...mapState('report/payslip', [
            'payslipTemplates',
            'employeeProfiles',
            'payslip'
        ])
    },
    methods: {
        ...mapActions('report/payslip', ['formViewModel']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },

        searchemployee: debounce(async function (query) {
            if (this.employeeProfile.length > 0) {
                var avoidSearch = this.employeeProfile.find(
                    emp =>
                        emp.card_id +
                            '|' +
                            emp.employee_name_kh +
                            '|' +
                            emp.employee_name_en ==
                        query.trim()
                )
                if (avoidSearch) return
            }
            this.fetchResource({
                fnName: 'employeeProfile',
                emp_search: query,
                branch_id: this.branch_id
            })
        }, 500),
        cycleYearChange (cycle_year) {
            this.cycle_year = cycle_year
        },
        cycleMonthChange (cycle_month) {
            this.cycle_month = cycle_month
        },
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.branches = response.data
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async fetchData (attributes) {
            this.viewing = true
            this.$store
                .dispatch('report/payslip/fetch', {
                    template_id: this.template_id,
                    employee_id: this.employee_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    branch_id: this.branch_id,
                    ...attributes
                })
                .then(() => {
                    this.viewing = false
                })
                .catch(error => {
                    this.viewing = false
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
        },
        exportExcel (attributes) {
            this.errors = new Errors()
            this.exportingExcel = true
            this.$store
                .dispatch('report/payslip/exportExcel', {
                    template_id: this.template_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    branch_id: this.branch_id,
                    employee_id: this.employee_id,
                    ...attributes
                })
                .then(response => {
                    let filename = trim(response.headers['content-disposition'])
                    FileDownload(response.data, filename)
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => (this.exportingExcel = false))
        },
        exportPdf (attributes) {
            this.showForm = true
            this.errors = new Errors()
            this.previewing = true
            this.$store
                .dispatch('report/payslip/previewPdf', {
                    template_id: this.template_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    branch_id: this.branch_id,
                    employee_id: this.employee_id,
                    ...attributes
                })
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.previewing = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PAYSLIP',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource({
                fnName: 'payslipTemplate'
            })
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('report/payslip/RESET_STATE')
        next()
    },
    watch: {
        branch_id: debounce(function (value) {
            this.employee_id = []
            this.fetchResource({
                fnName: 'employeeProfile',
                branch_id: value
            })
        }, 500)
    }
}
</script>
<style scoped>
#container {
    width: 100%;
    height: 80vh;
    background: rgb(93, 94, 95);
    text-align: center;
}
#container i {
    margin-top: 40vh;
    color: white;
}
</style>
